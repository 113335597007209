import { Link } from "react-router-dom";
import useGetAllDisplayProudct from "../hook/useGetAllDisplayProudct";
import Cookies from "js-cookie";
import { useEffect, useState,useContext } from "react";
import Swal from "sweetalert2";
import addToCartTodatabase from "../apiCalls/addToCart"
import { CartContext } from "../services/context/cartContext";

const Swiper = ()=>{
  const{updateCartFromAPI} = useContext(CartContext);
    const [data]=useGetAllDisplayProudct();
    const [isLogin,setIsLogin] = useState(false);
    useEffect(()=>{
      if(Cookies.get("AdminToken")!==undefined || Cookies.get("UserToken")!==undefined){
        setIsLogin(true);
      }
      else{
        setIsLogin(false);
      }
    },[])

  const addtoCart = async (event,p_id,qty,price)=>{
    event.preventDefault();
    event.stopPropagation();
    if(!isLogin){
      Swal.fire("Please Login First!");
    }
    else{
      const temp = await addToCartTodatabase(p_id,qty,price);
      if(temp){
        Swal.fire({
          icon: "success",
          title: "successfully added to cart",
          showConfirmButton: false,
          timer: 1500,
        });
        updateCartFromAPI();

      }
      else{
         Swal.fire("something went wrong");
      }
    }
    

  }


    return (
      <>
        <div className="w-[90%] px-4 mx-auto  pb-7">
          <div className=" flex">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] dark:text-white">
                  Out Popular Product
                </h2>
                <span className="mx-auto mb-4 block h-1 w-24 bg-blue-600"></span>
                <p className="text-base text-gray-400">
                Explore our best-selling plastic taps, faucets, and plumbing solutions, trusted for quality and durability.
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-3 pb-5 overflow-x-auto">
            {data?.map((item, index) => (
              <Link
                to={`/productDetails/${item.p_id}`}
                key={index}
                className="hover:shadow-2xl shadow-md rounded-xl flex-shrink-0 w-[12rem] md:w-[20rem] text-center"
              >
                
                  <img
                    loading="lazy"
                    className="rounded-t-lg h-40 md:h-80 w-full"
                    src={`${process.env.REACT_APP_PHP_APP_SERVER_URL}/${item.img}`}
                    alt="product"
                  />
                
                <div className="px-5 pb-5">
                  
                    <h3 className="text-gray-900 font-bold text-2xl tracking-tight mt-5">
                      ₹{item.p_title}
                    </h3>
                

                  <p className="text-xl font-semibold text-gray-500 my-2">
                    ₹{item.price}
                  </p>
                  <button
                    id="add_to_cart_btn"
                    onClick={(event)=>{ addtoCart(event,`${item.p_id}`,1,item.price)}}
                    className="text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-semibold rounded-lg px-8 py-2.5 text-center text-sm lg:text-lg"
                  >
                    Add to cart
                  </button>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </>
    );
}
export default Swiper;