import { Link, useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { MdSearch } from "react-icons/md";
import useGetAllOrder from "../hook/useGetAllOrder";
import { useEffect, useState } from "react";

const AdminOrder = () => {
  const navigate = useNavigate();
  const [data] = useGetAllOrder();
  const [isComplete, setIsComplete] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [serchValue, setSearchValue] = useState("");
  console.log(data);

  useEffect(() => {
    if (data.length > 0) {
      const orderStatusList = data.map((item) => item.order_status);

      setIsComplete(orderStatusList);
      setFilterData(data);
    }
  }, [data]);

  const search = (e) => {
    e.preventDefault();
    const temp = serchValue
      ? filterData?.filter((item) =>
          item.user_name.toLowerCase().includes(serchValue)
        )
      : data;
    setFilterData(temp);
  };

  return (
    <>
      <div className="">
        <div className="flex flex-row gap-2">
          <div className="w-full">
            <h1 className="font-bold p-5 text-2xl">Orders</h1>
            <form onSubmit={search}>
              <div className="my-5 px-6">
                <div className="flex items-center justify-between gap-4">
                  <div className="flex space-x-4">
                    <div className="flex w-full border border-gray-400 rounded-[7px]">
                      <input
                        type="text"
                        name="serchValue"
                        className="w-full rounded-l-md focus:outline-none pl-2"
                        placeholder="Search"
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                        }}
                      />

                      <button
                        type="submit"
                        className="bg-blue-600 text-white px-6 text-base font-semibold py-2 focus:outline-none focus:ring focus:border border-blue-600 rounded-r-md"
                      >
                        <MdSearch className="text-2xl" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div
              className="mt-4 mx-6 overflow-y-auto"
              style={{ height: "600px" }}
            >
              <div className="w-full overflow-hidden rounded-lg border border-gray-400">
                <div className="w-full overflow-x-auto">
                  <table className="w-full text-center">
                    <thead>
                      <tr className="text-xs font-semibold tracking-wide text-gray-700 uppercase border-b bg-gray-400 text-center">
                        <th className="px-4 py-3">Order No</th>
                        <th className="px-4 py-3">Client</th>
                        <th className="px-4 py-3">Total Quantity</th>
                        <th className="px-4 py-3">Price</th>
                        <th className="px-4 py-3">Status</th>
                        <th className="px-4 py-3">Order Date</th>
                        <th className="px-4 py-3 w-48"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y ">
                      {filterData?.map((item, index) => {
                        return (
                          <tr
                            className="bg-gray-50 hover:bg-gray-100 text-gray-700"
                            onClick={() => {
                              navigate(`/admin/orderDetails/${item.order_id}`);
                            }}
                            key={index}
                          >
                            <td className="px-4 py-3">{index + 1}</td>
                            <td className="px-4 py-3">
                              <div className="text-sm">
                                <p className="font-semibold">
                                  {item.user_name}
                                </p>
                              </div>
                            </td>
                            <td className="px-4 py-3">{item.total_qty}</td>
                            <td className="px-4 py-3">{item.total_amount}</td>

                            <td className="px-4 py-3 w-fit ">
                              <span
                                className={`${
                                  isComplete[index] === "0"
                                    ? "bg-red-400"
                                    : "bg-green-400"
                                } text-white rounded px-2 py-0.5`}
                              >
                                {isComplete[index] === "0"
                                  ? "pennding"
                                  : "compleate"}
                              </span>
                            </td>
                            <td className="px-4 py-3">{item.placed_date}</td>
                            <td className="px-4 py-3 flex justify-center">
                              <Link
                                to={`/admin/orderDetails/${item.order_id}`}
                                style={{ fontSize: "18px" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <FaEye className="text-xl text-gray-500" />
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminOrder;
